/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_lTLG8XjIw",
    "USER_POOL_APP_CLIENT_ID": "5mbmjps6qdva6g4p5kvmb8qjga"
};

export default awsmobile;
