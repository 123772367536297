import './App.css';
import React, { useState, useEffect, Component, useRef } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';
import { createChart, ColorType } from 'lightweight-charts';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


function App() {

/**
 * Declare state and constant varibles
 */

 // Chart settings
 const [chartData, setChartData] = useState(null);
 const [chartTimeframe, setChartTimeframe] = useState("1d");
 const [chartResolution, setChartResolution] = useState("1m");

 // Stats settings
 const [statsData, setStatsData] = useState(null);
 const [statsWindow, setStatsWindow] = useState(500);
 const [statsResolution, setStatsResolution] = useState("60m");

 // Benchmark settings
 const [benchData, setBenchData] = useState(null);
 const [benchWindow, setBenchWindow] = useState(500);
 const [benchResolution, setBenchResolution] = useState("60m");

 // API
 const [error, setError] = useState(null);

 // Trading View chart
 const resizeObserver = useRef();

 // Proxy URL
 const proxyUrl = "https://cryptic-peak-18087.herokuapp.com/"

 /**
 * Functions
 */

  
 function refreshChartData(chartTimeframe, chartResolution) {
  // Refresh chart data
  var baseUrl = "https://wt33jxe4yl.execute-api.us-east-1.amazonaws.com/v1/equity-tradestation-history"
  var endpointUrl = proxyUrl + baseUrl + "?timeframe="+chartTimeframe+"&resolution="+chartResolution

  fetch(endpointUrl)
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        } 
        return res.json();
      })
      .then(data => {
        setChartData(data);
        setError(null);
      })
      .catch(err => {
        // auto catches network / connection error
        setError(err.message);
      })
};


function refreshStatsData(statsWindow, statsResolution) {
  // Refresh Stats
  var baseUrl = "https://wt33jxe4yl.execute-api.us-east-1.amazonaws.com/v1/equity-tradestation-stats"
  var endpointUrl = proxyUrl + baseUrl + "?window="+statsWindow+"&resolution="+statsResolution

  fetch(endpointUrl)
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        } 
        return res.json();
      })
      .then(data => {
        setStatsData(data);
        setError(null);
      })
      .catch(err => {
        // auto catches network / connection error
        setError(err.message);
      })
};


function refreshBenchData(benchWindow, benchResolution) {
  // Refresh Stats
  var baseUrl = "https://wt33jxe4yl.execute-api.us-east-1.amazonaws.com/v1/sp500-benchmark"
  var endpointUrl = proxyUrl + baseUrl + "?window="+benchWindow+"&resolution="+benchResolution

  fetch(endpointUrl)
      .then(res => {
        if (!res.ok) { // error coming back from server
          throw Error('could not fetch the data for that resource');
        } 
        return res.json();
      })
      .then(data => {
        setBenchData(data);
        setError(null);
      })
      .catch(err => {
        // auto catches network / connection error
        setError(err.message);
      })
};


 useEffect(() => {
  refreshChartData(chartTimeframe, chartResolution);
  refreshStatsData(statsWindow, statsResolution);
  refreshBenchData(benchWindow, benchResolution);
}, []);

useEffect(() => {
  // Refresh API endpoints every 60 seconds
  const interval = setInterval(() => {
    refreshChartData(chartTimeframe, chartResolution);
    refreshStatsData(statsWindow, statsResolution);
  }, 60000);
  return () => clearInterval(interval);
}, [chartTimeframe, chartResolution, statsWindow, statsResolution]);


useEffect(() => {
  // Refresh chart data if parameters change
  refreshChartData(chartTimeframe, chartResolution)
}, [chartTimeframe, chartResolution])

useEffect(() => {
  // Refresh stats data if parameters change
  refreshStatsData(statsWindow, statsResolution)
}, [statsWindow, statsResolution])

useEffect(() => {
  // Refresh bench data if parameters change
  refreshBenchData(benchWindow, benchResolution)
}, [benchWindow, benchResolution])

// Color config
const backgroundColor = 'white'
const lineColor = '#2962FF'
const textColor = 'black'
const areaTopColor = '#2962FF'
const areaBottomColor = 'rgba(41, 98, 255, 0.28)'

// Chart container
const chartContainerRef = useRef();

useEffect(
  () => {
    
    if (chartData && statsData) {
      const handleResize = () => {
        chart.applyOptions({ width: chartContainerRef.current.clientWidth});
      };
  
      const chart = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        width: chartContainerRef.current.clientWidth,
        height: 500,
        timeScale: {
          borderVisible: false,
          timeVisible: true
        }
      });
      chart.timeScale().fitContent();
  
      const newSeries = chart.addAreaSeries({ lineColor, topColor: areaTopColor, bottomColor: areaBottomColor });
      newSeries.setData(JSON.parse(chartData.equity));
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
  
        chart.remove();
    };
    
    };

  },
  [chartData, chartTimeframe, chartResolution]
);

  return (
    <div className="App">


      <h1>DRS Topline</h1>
      <br />



      <label for="chartTimeframe">Timeframe </label>
          <select name="chartTimeframe" required="required" type="text" placeholder="1d" onChange={(x) => setChartTimeframe(x.target.value)}>
            <option value="1d">1d</option>
            <option value="1m">1m</option>
            <option value="3m">3m</option>
            <option value="5m">5m</option>
            <option value="15m">15m</option>
            <option value="30m">30m</option>
            <option value="60m">60m</option>
            <option value="1h">1h</option>
            <option value="2h">2h</option>
            <option value="4h">4h</option>
            <option value="8h">8h</option>
            <option value="12h">12h</option>
            <option value="1d">1d</option>
            <option value="2d">2d</option>
            <option value="3d">3d</option>
            <option value="1w">1w</option>
            <option value="2w">2w</option>
            <option value="1mo">1mo</option>
            <option value="2mo">2mo</option>
            <option value="3mo">3mo</option>
            <option value="6mo">6mo</option>
            <option value="1y">1y</option>
          </select>


      <label for="chartResolution">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Resolution </label>
           <select name="chartResolution" required="required" type="text" placeholder="1m" onChange={(x) => setChartResolution(x.target.value)}>
           <option value="1m">1m</option>
           <option value="3m">3m</option>
           <option value="5m">5m</option>
           <option value="15m">15m</option>
           <option value="30m">30m</option>
           <option value="60m">60m</option>
           <option value="90m">90m</option>
           <option value="1h">1h</option>
           <option value="2h">2h</option>
           <option value="4h">4h</option>
           <option value="8h">8h</option>
           <option value="12h">12h</option>
            <option value="1d">1d</option>
            <option value="2d">2d</option>
            <option value="3d">3d</option>
            <option value="1w">1w</option>
            <option value="1mo">1mo</option>
          </select>
          <br /><br />




      { error && <div>{ error }</div> }
      <div ref={chartContainerRef}/>

      
  
      {statsData && chartData && <div>
        <hr></hr>

        <h2>Equity</h2>

        <label for="statsWindow">Window </label>
            <select name="statsWindow" required="required" type="text" placeholder="500" onChange={(x) => setStatsWindow(parseInt(x.target.value))}>
            <option value="500">500</option>
            <option value="250">250</option>
            <option value="200">200</option>
            <option value="100">100</option>
            <option value="50">500</option>
            </select>

        <label for="statsResolution">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Resolution </label>
            <select name="statsResolution" required="required" type="text" placeholder="60m" onChange={(x) => setStatsResolution(x.target.value)}>
            <option value="60m">60m</option>
            <option value="1m">1m</option>
            <option value="3m">3m</option>
            <option value="5m">5m</option>
            <option value="15m">15m</option>
            <option value="30m">30m</option>
            <option value="60m">60m</option>
            <option value="90m">90m</option>
            <option value="1h">1h</option>
            <option value="2h">2h</option>
            <option value="4h">4h</option>
            <option value="8h">8h</option>
            <option value="12h">12h</option>
            <option value="1d">1d</option>
            <option value="2d">2d</option>
            <option value="3d">3d</option>
            <option value="1w">1w</option>
            <option value="1mo">1mo</option>
            </select>
            <br />
      </div>}

      <br /><br />
      {statsData && chartData && 
      <table width="100%">
        
          <tr>
            <th>Pnl %</th>
            <th>Pnl USD</th>
            <th>D Score</th>
            <th>Risk Efficiency</th>
            <th>Implied Risk Capital</th>

          </tr>
          <tr>
            <td>{JSON.parse(statsData.stats).pnl_pct.toFixed(2)}</td>
            <td>{JSON.parse(statsData.stats).pnl_usd.toFixed(2)}</td>
            <td>{JSON.parse(statsData.stats).dscore.toFixed(2)}</td>
            <td>{JSON.parse(statsData.stats).risk_efficiency.toFixed(2)}</td>
            <td>{JSON.parse(statsData.stats).implied_risk_capital.toFixed(2)}</td>
          </tr>
        </table>}

      
        {benchData && chartData && <div>
        <br /><br />

        <hr></hr>

        <h2>S&P 500</h2>

        <label for="benchWindow">Window </label>
            <select name="benchWindow" required="required" type="text" placeholder="500" onChange={(x) => setBenchWindow(parseInt(x.target.value))}>
            <option value="500">500</option>
            <option value="250">250</option>
            <option value="200">200</option>
            <option value="100">100</option>
            <option value="50">500</option>
            </select>

        <label for="benchResolution">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Resolution </label>
            <select name="benchResolution" required="required" type="text" placeholder="60m" onChange={(x) => setBenchResolution(x.target.value)}>
            <option value="60m">60m</option>
            <option value="30m">30m</option>
            <option value="90m">90m</option>
            <option value="120m">120m</option>
            <option value="1h">1h</option>
            <option value="2h">2h</option>
            <option value="4h">4h</option>
            <option value="8h">8h</option>
            <option value="12h">12h</option>
            <option value="1d">1d</option>
            <option value="2d">2d</option>
            <option value="3d">3d</option>
            <option value="1w">1w</option>
            </select>
            <br />
      </div>}


        <br /><br />
      {benchData && chartData && 
      <div>
        <table width="100%">
        
        <tr>
          <th>Pnl %</th>
          <th>Pnl USD</th>
          <th>D Score</th>
          <th>Risk Efficiency</th>
          <th>Implied Risk Capital</th>

        </tr>
        <tr>
          <td>{JSON.parse(benchData.stats).pnl_pct.toFixed(2)}</td>
          <td>{JSON.parse(benchData.stats).pnl_usd.toFixed(2)}</td>
          <td>{JSON.parse(benchData.stats).dscore.toFixed(2)}</td>
          <td>{JSON.parse(benchData.stats).risk_efficiency.toFixed(2)}</td>
          <td>{JSON.parse(benchData.stats).implied_risk_capital.toFixed(2)}</td>
        </tr>
      </table>
      <br/><br/>
      <hr></hr>
      </div>
      }
        <br/><br/><br/>

    
    </div>
  );
}

export default withAuthenticator(App);
